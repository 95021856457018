import { BsExclamationCircle } from 'react-icons/bs';

import { css } from '@emotion/react';

import { colors } from '~/themes/signin';

type Severity = 'success' | 'error';

interface Props {
	severity: Severity;
	className?: string;
}

const Alert: React.FC<Props> = ({ severity, className, children }) => {
	return (
		<div role="alert" css={styles(severity)} className={className}>
			<div>{severity === 'error' && <BsExclamationCircle aria-hidden="true" />}</div>
			<div>{children}</div>
		</div>
	);
};

export default Alert;

const styles = (severity: Severity) => css`
	padding: 8px;
	font-size: 18px;
	border: 1px solid;
	border-radius: 3px;
	display: flex;
	align-items: start;

	svg {
		margin-right: 5px;
		margin-top: 4px;
		max-width: none;
	}

	${severity === 'error' &&
	css`
		border-color: ${colors.red};
		background-color: ${colors.redBackground};

		svg {
			color: ${colors.red};
		}
	`}

	${severity === 'success' &&
	css`
		border-color: ${colors.green};
		background-color: ${colors.greenBackground};

		svg {
			color: ${colors.green};
		}
	`}
`;
