import { useCallback, useEffect, useState } from 'react';
import type { FormEventHandler } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { css } from '@emotion/react';
import axios from 'axios';

import { breakpoints } from '@soomo/lib/styles/themes';
import { logError } from '~/utils/rollbar';

import Alert from './Alert';
import Button from './Button';
import { endpoints, routerPath } from './constants';
import { messageStyles, formStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';
import PasswordResetConfirmation from './PasswordResetConfirmation';

interface PasswordsResetsPayload {
	user: {
		password: string;
		password_confirmation: string;
	};
	password_reset_token: string;
}

const EnterNewPassword: React.FC = () => {
	const { token } = useParams<{ token?: string }>();
	const navigate = useNavigate();

	const [formData, setFormData] = useState({
		password: '',
		passwordConfirmation: ''
	});

	const [isPasswordMatching, setIsPasswordMatching] = useState(true);
	const [isSubmitted, setSubmitted] = useState(false);

	useEffect(() => {
		if (!token) {
			navigate(routerPath.signInRoleSelection, { replace: true });
		}
	}, [navigate, token]);

	const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
		async (e) => {
			e.preventDefault();

			if (formData.password !== formData.passwordConfirmation) {
				setIsPasswordMatching(false);
				return;
			}

			const payload: PasswordsResetsPayload = {
				user: {
					password: formData.password,
					password_confirmation: formData.passwordConfirmation
				},
				password_reset_token: token!
			};

			try {
				await axios.put(`${endpoints.passwordResets}/${token}`, payload);
				setSubmitted(true);
			} catch (e) {
				logError(e);
			}
		},
		[token, formData]
	);

	return isSubmitted ? (
		<PasswordResetConfirmation />
	) : (
		<PageWrapper pageHeading="Enter new password" title="New Password | Soomo">
			<div css={messageStyles}>
				{isPasswordMatching ? (
					'Enter your new password in both fields below.'
				) : (
					<Alert severity="error">Password confirmation doesn’t match password.</Alert>
				)}
			</div>
			<form css={formStyles} onSubmit={handleFormSubmit}>
				<label htmlFor="password">New password</label>
				<input
					value={formData.password}
					id="password"
					type="password"
					placeholder="New Password"
					autoComplete="new-password"
					required
					minLength={8}
					onChange={(e) => setFormData({ ...formData, password: e.target.value })}
				/>

				<label htmlFor="confirm-password">Confirm password</label>
				<input
					value={formData.passwordConfirmation}
					id="confirm-password"
					type="password"
					placeholder="Confirm Password"
					autoComplete="new-password"
					required
					minLength={8}
					onChange={(e) => setFormData({ ...formData, passwordConfirmation: e.target.value })}
				/>
				<div css={footerStyles}>
					<div css={passwordRequirementStyles}>Password must be at least 8 characters long.</div>
					<Button type="submit">Reset</Button>
				</div>
			</form>
		</PageWrapper>
	);
};

export default EnterNewPassword;

const footerStyles = css`
	display: flex;
	justify-content: space-between;

	@media (max-width: ${breakpoints.small}) {
		flex-direction: column;

		button {
			align-self: end;
			margin-top: 10px;
		}
	}
`;

const passwordRequirementStyles = css`
	font-style: italic;
	font-size: 15px;
`;
