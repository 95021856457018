import { Link } from 'react-router-dom';

import { css } from '@emotion/react';

import { routerPath } from './constants';
import { linkStyles, messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

const PasswordResetConfirmation: React.VFC = () => {
	return (
		<PageWrapper pageHeading="Your password is reset" title="Password Reset | Soomo">
			<div css={messageStyles}>You may now use your new password to sign in.</div>

			<Link to={routerPath.signInRoleSelection} css={[linkStyles, backToSigninStyles]}>
				Back to sign-in
			</Link>
		</PageWrapper>
	);
};

export default PasswordResetConfirmation;

const backToSigninStyles = css`
	margin-top: 3.5rem;
`;
