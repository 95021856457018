import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { css } from '@emotion/react';

import { breakpoints } from '@soomo/lib/styles/themes';

import Button from './Button';
import { routerPath } from './constants';
import PageWrapper from './PageWrapper';

enum StatusMessage {
	TokenExpired = 'token-expired',
	SignedOut = 'signed-out',
	SessionExpired = 'session-expired',
	SessionMissing = 'session-missing'
}

const WelcomePage: React.FC = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const status = queryParams.get('status') as StatusMessage;

	const message = useMemo(() => {
		switch (status) {
			case StatusMessage.TokenExpired:
				return 'The link you clicked has expired.';
			case StatusMessage.SignedOut:
				return 'You have signed out and your session has been cleared. Thanks for using Soomo!';
			case StatusMessage.SessionExpired:
				return 'Your session has expired.';
			case StatusMessage.SessionMissing:
				return 'Your previous session was not found.';
		}
	}, [status]);

	const handleStudentClick = () => {
		navigate(routerPath.studentSignIn);
	};

	const handleInstructorClick = () => {
		navigate(routerPath.instructorSignIn);
	};

	return (
		<PageWrapper pageHeading="Sign in" title="Sign In | Soomo" alertMessage={message}>
			<div css={styles}>
				<div className="message">Are you a student or an instructor?</div>
				<div className="buttons-block">
					<Button onClick={handleStudentClick} className="redirect-button">
						I&rsquo;m a student
					</Button>
					<Button onClick={handleInstructorClick} className="redirect-button">
						I&rsquo;m an instructor
					</Button>
				</div>
			</div>
		</PageWrapper>
	);
};

export default WelcomePage;

const styles = css`
	width: 100%;

	.message {
		font-size: 28px;
		margin-top: 17px;
		text-align: center;
	}

	.buttons-block {
		margin-top: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.redirect-button {
		width: 215px;
	}

	@media (max-width: ${breakpoints.small}) {
		.message {
			font-size: 18px;
		}

		.redirect-button {
			width: 100%;
		}
	}
`;
