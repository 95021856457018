import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';
import axios from 'axios';

import { breakpoints } from '@soomo/lib/styles/themes';
import { logError } from '~/utils/rollbar';

import Alert from './Alert';
import Button from './Button';
import { endpoints, routerPath } from './constants';
import { WebtextSignInContext } from './context';
import PageWrapper from './PageWrapper';

const InstructorSignIn: React.FC = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

	const [error, setError] = useState(null);

	const { marketingHost } = useContext(WebtextSignInContext);

	const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		const payload = {
			email_address: formData.email,
			password: formData.password
		};

		try {
			const response = await axios.post(endpoints.signin, payload, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
			window.location.href = response.data.redirectPath;
		} catch (error) {
			setError(error);
			logError(error);
			setFormData({ ...formData, password: '' });
		}
	};

	return (
		// n.b. difference in casing is intentional (pageHeading has a lowercase 's', title has an uppercase 'S')
		<PageWrapper pageHeading="Instructor sign-in" title="Instructor Sign-in | Soomo">
			{error && (
				<div css={messageStyles}>
					<Alert severity="error">
						Your email or password did not work. If you need to reset your password, click the set
						or reset password link.
					</Alert>
				</div>
			)}
			<div css={formStyles}>
				<form onSubmit={handleFormSubmit}>
					<label htmlFor="email">Email address</label>
					<input
						value={formData.email}
						id="email"
						placeholder="Email Address"
						type="email"
						autoComplete="username"
						required
						onChange={(e) => setFormData({ ...formData, email: e.target.value })}
					/>

					<label htmlFor="password">Password</label>
					<input
						value={formData.password}
						id="password"
						placeholder="Password"
						type="password"
						autoComplete="current-password"
						required
						onChange={(e) => setFormData({ ...formData, password: e.target.value })}
					/>

					<div className="form-footer">
						<div className="links-block">
							<a
								href={`https://${marketingHost}/get-started`}
								target="_blank"
								rel="noreferrer"
								title="Soomo Learning Get Started"
								css={linkStyles}>
								New instructor sign-up
							</a>
							<Link to={routerPath.instructorResetPassword} css={linkStyles}>
								Set or reset your password
							</Link>
						</div>

						<Button type="submit">Sign in</Button>
					</div>
				</form>
			</div>
		</PageWrapper>
	);
};

export default InstructorSignIn;

export const messageStyles = css`
	max-width: 80%;
	font-size: 22px;
	margin-top: 10px;
	text-align: center;
	overflow-wrap: break-word;

	@media (max-width: ${breakpoints.small}) {
		font-size: 18px;
		max-width: none;
		width: 100%;
	}
`;

export const linkStyles = css`
	font-size: 18px;
	font-weight: 600;
	color: #0002e6;
	white-space: nowrap;

	:not(:last-of-type) {
		margin-bottom: 15px;
	}

	@media (max-width: ${breakpoints.small}) {
		font-size: 16px;

		:not(:last-of-type) {
			margin-bottom: 10px;
		}
	}
`;

export const formStyles = css`
	width: 100%;
	max-width: 500px;
	margin-top: 30px;

	label {
		font-size: 22px;
		font-weight: 500;
		line-height: 1.2;
		color: black;
		display: block;
		margin-bottom: 6px;
	}

	input {
		width: 100%;
		box-sizing: border-box;
		padding: 15px 20px;
		font-size: 22px;
		border: 1px solid #979797;
		display: block;
		margin-bottom: 20px;
	}

	.form-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.links-block {
		display: flex;
		flex-direction: column;
		margin-right: 15px;
	}

	@media (max-width: ${breakpoints.small}) {
		label,
		input {
			font-size: 18px;
		}
	}
`;
